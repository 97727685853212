<style lang="sass" scoped>
.container-table
  font-size: 12px
  // overflow: scroll
  // position: relative
  // height: calc(100% - 300px)
  // height: 300px
  .sticky-header
    position: sticky
    top: -1px
    // left: 0px
    // display: inline-block
    background-color: #fff
    z-index: 1000
    // border-bottom: solid 2px #ccc
    outline: solid 1px #ccc
    border-bottom: 0
    // font-weight: 800
    color: #333 !important
  .link-view-out
    text-decoration: none
    &.router-link-active
      background-color: #f0f0f0
    .link-view
      color: #007bff
      border-bottom: solid 0.05em #007bff
      font-weight: bold
      transition: color linear 100ms, border linear 100ms
    &:hover
      .link-view
        color: #333
        border-color: lighten(#333, 30%)
        text-decoration: none
    &:visited
      .link-view
        color: #555
        border-bottom: solid 0.05em lighten(#555, 30%)
      &:hover
        .link-view
          color: #333

.container-pane
  position: fixed
  bottom: 0px
  right: 0px
  width: calc(100vw - 370px - 0px)
  height: 50vh
  overflow: scroll
  border-color: #555 !important

  .th
    vertical-align: middle
    // vertical-align: top
    // position: sticky
    // top: 0px
    // background-color: #fafafa
    // border-bottom: solid 1px #ccc

.text-body
  text-overflow: ellipsis
  // white-space: pre
  word-wrap: nowrap
  height: 1.5rem
  overflow: hidden

.container-search-pad
  padding-top: 53px
</style>
<template lang="pug">
.container-search-pad
  div.container-search.bg-white.shadow-sm.pl-2
    router-link.link-back(:to='`/property/${property_id}/message/all`')
      span.text-dark(style='font-size: 18px; line-height: 50px;')
        b-icon-chevron-left
        strong(style='padding-left: 5px; font-size: 12px; line-height: 50px; color: #333') 발송내역
    router-link.link-hover(:to='`/property/${property_id}/message/${message_id}/audience`')
      strong.text-secondary.ml-4.mr-2 {{row.title}}
    //- a.btn.text-secondary.py-1.px-2(href='#' onclick='alert(`메뉴 설정 모달 띄움. 좌측 대메뉴에 추가`)' style='font-size: 11px; opacity: 0.5')
      b-icon-star

  .bg-white.shadow-sm.mb-2
    .p-3
      h5 발송 종합정보
      table.table(v-if='row.form_json')
        tbody
          tr
            th.bg-light.w-25.text-right 제목
            td {{row.title}}
          tr
            th.bg-light.w-25.text-right 생성일시
            td.rounded {{row.created_at}}
              //- pre {{row.form_json}}
          tr(v-show='row.form_json.sms_body')
            th.bg-light.w-25.text-right 문자
            td
              textarea.form-control.bg-light.pt-3(v-model='row.form_json.sms_body' readonly rows=10 style='width: 15rem')
          tr(v-show='row.form_json.kakao_body.text')
            th.bg-light.w-25.text-right 카카오 알림톡
            td
              textarea.form-control.bg-white.pt-3(rows=5 v-model='row.form_json.kakao_body.text' readonly)
          tr(v-show='row.form_json.email_subject')
            th.bg-light.w-25.text-right 이메일
            td
              iframe.async.w-100.rounded.border-0(:class='{done: email_preview_url}' :src='email_preview_url' style='height: 50vh')
              template(v-if='email_preview_url == "N/A" ')
                input.form-control.mb-2.bg-white(type='text' v-model='row.form_json.email_subject' readonly)
                textarea.form-control.bg-white.pt-3(rows=5 v-model='row.form_json.email_body' readonly)
          tr
            th.bg-light.w-25.text-right 발송대상
            td
              router-link.btn.btn-light.text-left(:to='`/property/${property_id}/customer/${row.document_id}/${ $options.filters.encodeText(row.document_name) }`')
                small.d-block.text-muted 고객목록이름
                | {{row.document_name}}
              router-link.btn.btn-light.text-primary.ml-1.text-left(
                :to='`/property/${property_id}/customer/${row.document_id}-${row.form_json.saved_filter.id}/${ $options.filters.encodeText(row.document_name) }`'
                v-if='row.form_json.saved_filter && row.form_json.saved_filter.id'
              )
                small.d-block.text-muted 필터이름
                span {{row.form_json.saved_filter.name}}

                span.ml-1(v-if='row.form_json && row.form_json.filters && !row.form_json.current_filter_id')
                  span.btn.btn-default.btn-sm.bg-white.px-2.py-1.border.text-secondary.mr-1(type='button' @click.prevent='' disabled v-for='item in row.form_json.filters')
                    b-icon-person-fill(v-if='item.prekey == "search.customer"')
                    span(v-if='item.prekey == "search.field"') {{item.col.label}}:&nbsp;
                    span(v-if='item.key == "search.field.value_not_contain"') (제외)&nbsp;
                    span(v-if='item.key == "search.field.value_is_empty"') (비어있음)&nbsp;
                    span(v-if='item.key == "search.field.value_isnt_empty"') (비어있지않음)&nbsp;
                    span(v-if='item.key == "search.row.not_in_ids"') (고객아이디 제외)&nbsp;
                    span(v-if='item.key == "search.row.in_ids"') (고객아이디 지정)&nbsp;
                    span(v-if='item.prekey == "search.row"') {{item.value.length}}건&nbsp;
                    span(v-else) {{item.value}}
              router-link.btn.btn-light.text-primary.ml-1.text-left.py-2(
                :to='`/property/${property_id}/customer/${row.document_id}-${row.form_json.current_filter_id}/${ $options.filters.encodeText(row.document_name) }`'
                v-if='row.form_json.current_filter_id'
              )
                small.d-block.text-muted.mb-1 필터조건
                //- span 수정된 필터
                //- span(v-if='row.form_json.saved_filter.is_listed == "Y"') {{row.form_json.saved_filter.name || '(저장된 필터 이름없음)'}}
                //- span(v-else) {{row.form_json.saved_filter.name || '보고있는 필터로 메시지보내기'}}

                span(v-if='row.form_json && row.form_json.filters')
                  span.btn.btn-default.btn-sm.bg-white.px-2.py-1.border.text-secondary.mr-1(v-for='item in row.form_json.filters')
                    b-icon-person-fill(v-if='item.prekey == "search.customer"')
                    span(v-if='item.prekey == "search.field"') {{item.col.label}}:&nbsp;
                    span(v-if='item.key == "search.field.value_not_contain"') (제외)&nbsp;
                    span(v-if='item.key == "search.field.value_is_empty"') (비어있음)&nbsp;
                    span(v-if='item.key == "search.field.value_isnt_empty"') (비어있지않음)&nbsp;
                    span(v-if='item.key == "search.row.not_in_ids"') (고객아이디 제외)&nbsp;
                    span(v-if='item.key == "search.row.in_ids"') (고객아이디 지정)&nbsp;
                    span(v-if='item.prekey == "search.row"') {{item.value.length}}건&nbsp;
                    span(v-else) {{item.value}}
              //- pre {{document_stat}}
              .mb-2
              .row.async(:class="{done: document_stat.count_all}")
                .col
                  strong SMS
                  b-progress(:max='document_stat.count_all')
                    b-progress-bar(:value='message_stat.sms_count' variant='success')
                    //- b-progress-bar(:value='document_stat.count_all - document_stat.count_phone' variant='light')
                  button.btn.btn-default.btn-sm.text-muted.p-2.mt-1(type='button' @click='open_filter_sms_target') 대상자 {{message_stat.sms_count}}명
                  button.btn.btn-default.btn-sm.text-muted.p-2.mt-1.float-right(type='button' @click='open_filter_sms_out_target') 미발송 {{Math.max(0, document_stat.count_all - message_stat.sms_count)}}명

                  br
                  br
                  strong 발송상태
                    small.text-muted.ml-1 {{message_stat.sms_count}}명 중
                  b-progress(:max='message_stat.sms_count')
                    b-progress-bar(:value='message_stat.sms_success' variant='success')
                  button.btn.btn-default.btn-sm.text-muted.p-2.mt-1(type='button' @click='open_filter_sms_success') 성공 {{message_stat.sms_success}}명
                  button.btn.btn-default.btn-sm.text-muted.p-2.mt-1.float-right(type='button' @click='open_filter_sms_fail') 실패 {{message_stat.sms_fail}}명
                .col.border-left
                  strong EMAIL
                  b-progress(:max='document_stat.count_all')
                    b-progress-bar(:value='message_stat.email_count' variant='success')
                  button.btn.btn-default.btn-sm.text-muted.p-2.mt-1(type='button' @click='open_filter_email_target') 대상자 {{message_stat.email_count}}명
                  button.btn.btn-default.btn-sm.text-muted.p-2.mt-1.float-right(type='button' @click='open_filter_email_out_target') 미발송 {{Math.max(0, document_stat.count_all - message_stat.email_count)}}명

                  br
                  br
                  strong 발송상태
                    small.text-muted.ml-1 {{message_stat.email_count}}명 중
                  b-progress(:max='message_stat.email_count')
                    b-progress-bar(:value='message_stat.email_success' variant='success')
                  button.btn.btn-default.btn-sm.text-muted.p-2.mt-1(type='button' @click='open_filter_email_success') 성공 {{message_stat.email_success}}명
                  button.btn.btn-default.btn-sm.text-muted.p-2.mt-1.float-right(type='button' @click='open_filter_email_fail') 실패 {{message_stat.email_fail}}명

          //- tr
            th.bg-light.w-25.text-right 발송대상
            td.bg-light
          tr
            td.p-4.py-2(colspan=2)
              h5 개별 발송상태
              .mb-2
                button.btn.mr-2.font-weight-bold.py-2.rounded-pill.shadow-sm(type='button' @click='current_filter = "all"' :class='[current_filter == "all" ? "btn-primary" : "btn-light"]') 전체
                button.btn.mr-2.py-2.rounded-pill.shadow-sm(type='button' @click='current_filter = "success"' :class='[current_filter == "success" ? "btn-primary" : "btn-light"]') 성공
                button.btn.mr-2.py-2.rounded-pill.shadow-sm(type='button' @click='current_filter = "fail"' :class='[current_filter == "fail" ? "btn-primary" : "btn-light"]') 실패
                button.btn.mr-2.py-2.rounded-pill.shadow-sm(type='button' @click='current_filter = "delivered"' :class='[current_filter == "delivered" ? "btn-primary" : "btn-light"]') Delivered
                button.btn.mr-2.py-2.rounded-pill.shadow-sm(type='button' @click='current_filter = "not_delivered"' :class='[current_filter == "not_delivered" ? "btn-primary" : "btn-light"]') Not Delivered
                button.btn.mr-2.py-2.rounded-pill.shadow-sm(type='button' @click='current_filter = "open"' :class='[current_filter == "open" ? "btn-primary" : "btn-light"]') Open
                button.btn.mr-2.py-2.rounded-pill.shadow-sm(type='button' @click='current_filter = "not_open"' :class='[current_filter == "not_open" ? "btn-primary" : "btn-light"]') Not Open
                button.btn.mr-2.py-2.rounded-pill.shadow-sm(type='button' @click='current_filter = "click"' :class='[current_filter == "click" ? "btn-primary" : "btn-light"]') Clicked
                button.btn.mr-2.py-2.rounded-pill.shadow-sm(type='button' @click='current_filter = "not_click"' :class='[current_filter == "not_click" ? "btn-primary" : "btn-light"]') Not Clicked

                button.btn.btn-light.ml-2.py-2.float-right(type='button' @click='download_event') 전체 이벤트 다운로드
              .pb-2
              div(style='min-height: 500px')
                table.table.container-table.border-bottom
                  thead
                    tr
                      th 일시
                      th 고객정보
                      th 분류
                      th 상태
                      th Delivered
                      th Open
                      th Click
                      th
                  tbody
                    tr(v-for='item in rows' v-show='current_filter == "all" ? true : item.current_filter.includes(current_filter)')
                      td {{item.created_at}}
                      td
                        //- router-link.d-block.link-view-out(:to='`/property/${property_id}/customer/group/${item.external_id}`' target='_blank')
                          span.link-view {{item.external_id || '(조회)'}}
                        //- span.link-view {{item.external_id || '(삭제된 고객)'}}
                        //- router-link.d-block.link-view-out(:to='`/property/${property_id}/customer/${row.document_id}/${$options.filters.encodeText(row.document_name)}/${item.external_id}`')
                        a.d-block.link-view-out(href='#' @click.prevent='open_record(item)')
                          strong.bg-light.text-dark.p-1.mr-1(v-show='!item.external_id') 삭제된 고객
                          span.link-view {{item.customer_text}}
                      td {{item.type}}
                      td {{item.status_text}}
                      td {{item.delivered_at}}
                      td {{item.opened_at}}
                      td {{item.clicked_at}}
                      td
                        span.mr-2(v-if='item.type == "EMAIL" ')
                          a(:href='`${API_URL}/v1/DM/${item.id}/${item.uuid}`' target='_blank' @click.stop='') 웹페이지 열기
                        | {{item.error}}
                      //- td {{item.result_json}}
                      //- td {{item.opened_at}}
                      //- td {{item.clicked_at}}
                      //- td {{item.response_at}}
    .mb-2(style='font-size: 12px')
      modal.py-2(name='record' width='700px' :height='`auto`' :scrollable='true'
      )
        button.btn.btn-default.float-right.text-muted.rounded-0(type='button' @click='$modal.hide("record")' style='font-size: 1.5rem')
          b-icon-x
        router-link.button.btn.btn-default.float-right.text-muted.rounded-0.py-0(style='line-height: 65px' target='_blank' :to='`/property/${property_id}/customer/${row.document_id}/${$options.filters.encodeText(row.document_name)}/${record_id}`') 새창으로 열기
        .clearfix.py-4(style='min-height: 90vh' v-if='record_row_id')
          h5.px-4
            | {{row.title}}
            b-icon-chevron-right.text-muted.mx-2
            | {{row.document_name}}
            //- router-link.button.btn.btn-default.text-muted.rounded-0(:to='`/property/${property_id}/customer/${row.document_id}/${$options.filters.encodeText(row.document_name)}/${record_id}`') {{row.document_name}}
            //- router-link(:to='`/property/${property_id}/customer/${row.document_id}/${$options.filters.encodeText(row.document_name)}/${record_id}`')
            span.text-muted.ml-2 조회

            //- span.d-inline.float-right
              dropdown(:active='dropdown_group_record_active')
                template(slot='toggler')
                  button.btn.btn-default.btn-sm(type='button' style='font-size: 12px; padding: .25rem .5rem; color: #999')
                    | 연결된 레코드
                    b-icon-link45deg
                dropdown-content
                  //- dropdown-block.mt-1
                    input.form-control.form-control-sm.form-dropdown(type='text' value='목록 이름')
                  a.link-dropdown.text-left.p-2.d-block(href='#' @click.prevent='update_external_id(1)') @고객아이디
                  //- hr.my-2
          customer-record(
            :property_id='property_id'
            :document='document'
            :document_id='document.id'
            :record_row_id='record_row_id'
          )
          //- :record_id='record_id'


  //- pre {{rows}}
  //- .rounded.shadow

      //- tr
        th
        td
          button.btn.btn-primary(type='submit' :disabled='saving') {{saving_text}}
          button.btn.btn-default.text-danger.float-right(type='button' @click='remove') 삭제
    //- pre {{row}}

  //- form(@submit.prevent='submit')
    table.table(v-if='row.id')
      tbody
        tr.bg-light
          th.w-25.th.text-right ID
          td
            input.form-control(type='text' v-model='row.external_id' readonly autofocus)
        tr(v-for='(col, $index) in document.config.cols')
          th.th.text-right {{col.label}}
          td
            input.form-control(type='text' v-model='row.json[col.key]')
        tr
          th
          td
            button.btn.btn-primary(type='submit' :disabled='saving') {{saving_text}}
            button.btn.btn-default.text-danger.float-right(type='button' @click='remove') 삭제


</template>

<script>

import CustomerRecord from '@/components/CustomerRecord'

const xlsx = require('xlsx')
import moment from 'moment'

export default {
  name: 'index',
  props: ['property_id', 'message_id'],
  components: {
    CustomerRecord,
  },
  computed: {

  },
  watch: {
    'message_id'() {
      this.load()
    },
  },
  data() {
    return {
      record_id: null,
      record_row_id: null,
      document: {},

      API_URL: process.env.VUE_APP_BASE_API_URL,
      done: false,
      saving: false,
      saving_text: '저장',
      row: {},
      rows: [],
      document_stat: {},
      message_stat: {},

      current_filter: 'all',
      email_preview_url: null,
    }
  },
  async mounted() {
    // await this.$store.dispatch('status flows', this.property_id)
    // this.loaded = true
    // this.load()
    // if (this.$store.state.documents) {
    //   this.load()
    // }
    this.load()
  },
  methods: {
    open_record(item) {
      console.log({item})
      this.document = this.$store.state.documents_by_id[this.row.document_id]
      // this.record_id = item.external_id
      this.record_row_id = item.document_record_id
      this.$modal.show('record')
    },
    async open_filter_sms_target() {
      try {
        const ids = this.rows.filter(e => {
          return e.type == 'SMS'
        }).map(e => e.document_record_id)
        if (ids.length === 0) {
          return this.$bvToast.toast(`대상자가 없습니다. 0명`, {
            title: `알림`,
            variant: 'default',
            solid: true,
            toaster: 'b-toaster-bottom-right',
          })
        }
        const r2 = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.row.document_id}/saved-filters`, {
            filters: JSON.stringify([
              {
                ts: Date.now(),
                key: 'search.row.in_ids',
                value: ids,
                prekey: 'search.row',
              },
            ]),
          })
          if (r2?.data?.message != 'ok') throw new Error('필터 URL 가져오기 실패')
          const filter_id = r2.data.id

          this.$router.push({
            path: `/property/${this.property_id}/customer/${this.row.document_id}-${filter_id}/${this.$options.filters.encodeReadableText(this.row.document_name)}`,
          })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async open_filter_sms_out_target() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/views/messages/${this.message_id}/stats/sms-out-of-target`)
        if (r?.data?.message != 'ok') throw new Error('대상자 가져오기 실패')
        const ids = r.data.rows.map(e => e.id)
        if (ids.length === 0) {
          return this.$bvToast.toast(`대상자가 없습니다. 0명`, {
            title: `알림`,
            variant: 'default',
            solid: true,
            toaster: 'b-toaster-bottom-right',
          })
        }

        const r2 = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.row.document_id}/saved-filters`, {
          filters: JSON.stringify([
            {
              ts: Date.now(),
              key: 'search.row.in_ids',
              value: ids,
              prekey: 'search.row',
            },
          ]),
        })
        if (r2?.data?.message != 'ok') throw new Error('필터 URL 가져오기 실패')
        const filter_id = r2.data.id

        this.$router.push({
          path: `/property/${this.property_id}/customer/${this.row.document_id}-${filter_id}/${this.$options.filters.encodeReadableText(this.row.document_name)}`,
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async open_filter_sms_success() {
      try {
        const rows = this.rows.filter(e => {
          return e.type == 'SMS' && e.current_filter == 'success'
        })
        if (rows.length === 0) {
          return this.$bvToast.toast(`대상자가 없습니다. 0명`, {
            title: `알림`,
            variant: 'default',
            solid: true,
            toaster: 'b-toaster-bottom-right',
          })
        }

        const ids = rows.map(e => e.document_record_id)

        const r2 = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.row.document_id}/saved-filters`, {
          filters: JSON.stringify([
            {
              ts: Date.now(),
              key: 'search.row.in_ids',
              value: ids,
              prekey: 'search.row',
            },
          ]),
        })
        if (r2?.data?.message != 'ok') throw new Error('필터 URL 가져오기 실패')
        const filter_id = r2.data.id

        this.$router.push({
          path: `/property/${this.property_id}/customer/${this.row.document_id}-${filter_id}/${this.$options.filters.encodeReadableText(this.row.document_name)}`,
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async open_filter_sms_fail() {
      try {
        const rows = this.rows.filter(e => {
          return e.type == 'SMS' && e.current_filter == 'fail'
        })
        if (rows.length === 0) {
          return this.$bvToast.toast(`대상자가 없습니다. 0명`, {
            title: `알림`,
            variant: 'default',
            solid: true,
            toaster: 'b-toaster-bottom-right',
          })
        }

        const ids = rows.map(e => e.document_record_id)

        const r2 = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.row.document_id}/saved-filters`, {
          filters: JSON.stringify([
            {
              ts: Date.now(),
              key: 'search.row.in_ids',
              value: ids,
              prekey: 'search.row',
            },
          ]),
        })
        if (r2?.data?.message != 'ok') throw new Error('필터 URL 가져오기 실패')
        const filter_id = r2.data.id

        this.$router.push({
          path: `/property/${this.property_id}/customer/${this.row.document_id}-${filter_id}/${this.$options.filters.encodeReadableText(this.row.document_name)}`,
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },


    // EMAIL
    async open_filter_email_target() {
      try {
        const ids = this.rows.filter(e => {
          return e.type == 'EMAIL'
        }).map(e => e.document_record_id)
        if (ids.length === 0) {
          return this.$bvToast.toast(`대상자가 없습니다. 0명`, {
            title: `알림`,
            variant: 'default',
            solid: true,
            toaster: 'b-toaster-bottom-right',
          })
        }
        const r2 = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.row.document_id}/saved-filters`, {
            filters: JSON.stringify([
              {
                ts: Date.now(),
                key: 'search.row.in_ids',
                value: ids,
                prekey: 'search.row',
              },
            ]),
          })
          if (r2?.data?.message != 'ok') throw new Error('필터 URL 가져오기 실패')
          const filter_id = r2.data.id

          this.$router.push({
            path: `/property/${this.property_id}/customer/${this.row.document_id}-${filter_id}/${this.$options.filters.encodeReadableText(this.row.document_name)}`,
          })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async open_filter_email_out_target() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/views/messages/${this.message_id}/stats/email-out-of-target`)
        if (r?.data?.message != 'ok') throw new Error('대상자 가져오기 실패')
        const ids = r.data.rows.map(e => e.id)
        if (ids.length === 0) {
          return this.$bvToast.toast(`대상자가 없습니다. 0명`, {
            title: `알림`,
            variant: 'default',
            solid: true,
            toaster: 'b-toaster-bottom-right',
          })
        }

        const r2 = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.row.document_id}/saved-filters`, {
          filters: JSON.stringify([
            {
              ts: Date.now(),
              key: 'search.row.in_ids',
              value: ids,
              prekey: 'search.row',
            },
          ]),
        })
        if (r2?.data?.message != 'ok') throw new Error('필터 URL 가져오기 실패')
        const filter_id = r2.data.id

        this.$router.push({
          path: `/property/${this.property_id}/customer/${this.row.document_id}-${filter_id}/${this.$options.filters.encodeReadableText(this.row.document_name)}`,
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async open_filter_email_success() {
      try {
        const rows = this.rows.filter(e => {
          return e.type == 'EMAIL' && e.current_filter == 'success'
        })
        if (rows.length === 0) {
          return this.$bvToast.toast(`대상자가 없습니다. 0명`, {
            title: `알림`,
            variant: 'default',
            solid: true,
            toaster: 'b-toaster-bottom-right',
          })
        }

        const ids = rows.map(e => e.document_record_id)

        const r2 = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.row.document_id}/saved-filters`, {
          filters: JSON.stringify([
            {
              ts: Date.now(),
              key: 'search.row.in_ids',
              value: ids,
              prekey: 'search.row',
            },
          ]),
        })
        if (r2?.data?.message != 'ok') throw new Error('필터 URL 가져오기 실패')
        const filter_id = r2.data.id

        this.$router.push({
          path: `/property/${this.property_id}/customer/${this.row.document_id}-${filter_id}/${this.$options.filters.encodeReadableText(this.row.document_name)}`,
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async open_filter_email_fail() {
      try {
        const rows = this.rows.filter(e => {
          return e.type == 'EMAIL' && e.current_filter == 'fail'
        })
        if (rows.length === 0) {
          return this.$bvToast.toast(`대상자가 없습니다. 0명`, {
            title: `알림`,
            variant: 'default',
            solid: true,
            toaster: 'b-toaster-bottom-right',
          })
        }

        const ids = rows.map(e => e.document_record_id)

        const r2 = await this.$http.post(`/v1/property/${this.property_id}/views/documents/${this.row.document_id}/saved-filters`, {
          filters: JSON.stringify([
            {
              ts: Date.now(),
              key: 'search.row.in_ids',
              value: ids,
              prekey: 'search.row',
            },
          ]),
        })
        if (r2?.data?.message != 'ok') throw new Error('필터 URL 가져오기 실패')
        const filter_id = r2.data.id

        this.$router.push({
          path: `/property/${this.property_id}/customer/${this.row.document_id}-${filter_id}/${this.$options.filters.encodeReadableText(this.row.document_name)}`,
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    apply_filter(e) {
      e.current_filter = []
      if (e.status_text == '발송실패') {
        e.current_filter.push('fail')
      }
      if (e.status_text == '발송성공') {
        e.current_filter.push('success')
      }
      if (e.delivered_at) {
        e.current_filter.push('delivered')
      } else {
        e.current_filter.push('not_delivered')
      }
      if (e.clicked_at) {
        e.current_filter.push('click')
      } else {
        e.current_filter.push('not_click')
      }
      if (e.opened_at) {
        e.current_filter.push('open')
      } else {
        e.current_filter.push('not_open')
      }
    },
    async load() {
      this.$store.dispatch('documents', this.property_id)
      // this.document = Object.assign({}, this.$store.state.documents_by_id[this.document_id])
      // if (!this.document.id) {
      //   this.error = '해당 목록을 찾지 못했습니다.'
      //   return
      // }
      try {
        const r1 = await this.$http.get(`/v1/property/${this.property_id}/views/messages/${this.message_id}`, {

        })
        if (r1?.data?.message != 'ok') throw new Error('발송내역 가져오기 실패')
        this.row = r1.data.row

        const r = await this.$http.get(`/v1/property/${this.property_id}/views/messages/${this.message_id}/records`, {

        })
        if (r?.data?.message != 'ok') throw new Error('발송내역 고객목록 가져오기 실패')

        this.message_stat.sms_count = 0
        this.message_stat.sms_success = 0
        this.message_stat.sms_fail = 0
        this.message_stat.email_count = 0
        this.message_stat.email_success = 0
        this.message_stat.email_fail = 0
        this.rows = r.data.rows.map(e => {
          this.apply_filter(e)

          if (e.type == 'EMAIL') {
            this.message_stat.email_count++
            this.message_stat[`email_${e.current_filter}`]++
          } else if (e.type == 'SMS') {
            this.message_stat.sms_count++
            this.message_stat[`sms_${e.current_filter}`]++
          }
          return e
        })

        const r3 = await this.$http.get(`/v1/property/${this.property_id}/views/documents/${this.row.document_id}/count-audiences`)
        if (r3?.data?.message != 'ok') throw new Error('고객목록 통계 가져오기 오류. ' + r3.data.message)
        this.document_stat = r3.data

        if (this.row?.form_json?.email_body) {
          this.email_preview_url = `${this.API_URL}/v1/DM/template/${this.row.id}/${this.row.uuid}`
        } else {
          this.email_preview_url = 'N/A'
        }

        this.done = true
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async submit() {
      this.saving = true
      this.saving_text = '저장 중'
      try {
        const r = await this.$http.put(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/${decodeURIComponent(this.record_id)}`, {
          row: this.row.json,
        })
        if (r?.data?.message != 'ok') throw new Error('저장 실패. ' + r.data.message)

        if (this.row.external_id != r.data.external_id) {
          this.$router.push({
            path: `/property/${this.property_id}/customer/${this.document_id}/record/${r.data.external_id}`,
          })
        } else {
          this.load()
        }

        this.saving = false
        this.saving_text = '저장됨'

        this.$emit('updated')
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.saving = false
    },
    async remove() {
      try {
        if(!confirm('삭제하시겠습니까?')) return false

        const r = await this.$http.delete(`/v1/property/${this.property_id}/views/documents/${this.document_id}/records/${this.record_id}`)
        if (r?.data?.message != 'ok') throw new Error('저장 실패. ' + r.data.message)
        // this.load()

        this.$emit('updated')
        this.$router.push({
          path: `/property/${this.property_id}/customer/${this.document_id}`,
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
      this.saving = false
    },
    async download_event() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/views/messages/${this.message_id}/records/events`)
        if (r?.data?.message != 'ok') throw new Error('불러오기 실패. ' + r.data.message)
        if (r.data.rows.length === 0) throw new Error('조회된 이벤트가 없습니다. (0건)')
        // const rows = r.data.rows.filter(e => {
          //   this.apply_filter(e)
        //   return e.current_filter.includes(this.current_filter)
        // })
        const rows = r.data.rows
        const wb = xlsx.utils.book_new()
        const ws = xlsx.utils.json_to_sheet(rows)
        xlsx.utils.book_append_sheet(wb, ws, `${this.row.title} (${this.row.id})`)
        xlsx.writeFile(wb, `noitaler-message-record-export-${moment().format('YYYY.MM.DD') + '-' + Date.now()}.xlsx`);
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
  },
}
</script>
